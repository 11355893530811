<template>
  <div
    style="
      font-size: 13px;
      color: #999;
      height: 41px;
      overflow: hidden;
    "
  >
    <div class="filter" v-bind:class="{ filterOpen: filterOpen }">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 41px;
        "
      >
        <div>为您找到相关结果{{ total }}个</div>
        <div
          style="
            color: #409eff;
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;
          "
          @click="filterOpen = true"
        >
          <i class="iconfont iconfilter"></i>
          搜索工具
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 41px;
        "
      >
        <div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListFilter',
  props: {
    total: {
      type: Number,
      default: 0
    } // key code,
  },
  data() {
    return {
      filterOpen: true
    };
  },
  methods: {
    putAway() {
      this.filterOpen = false;
      this.$emit('close');
    }
  }
};
</script>
<style lang="scss">
.filter {
  transform: translateY(0);
  transition: all 0.3s;
}
.filterOpen {
  transform: translateY(-41px);
}
</style>
